import React from 'react';
import './BottomActions.scss';
import classNames from 'classnames';

interface BottomActionsProps {
  children?: JSX.Element;
  isWelcomeSection?: boolean;
  renderHelpTips?: () => JSX.Element;
}

const BottomActions: React.FC<BottomActionsProps> = ({ children, isWelcomeSection = true, renderHelpTips }) => {
  return (
    <div className={classNames('bg-white sticky-bottom', { 'pb-0': isWelcomeSection, 'pb-4': !isWelcomeSection })}>
      {renderHelpTips ? <>{renderHelpTips()}</> : null}
      <div className="bg-white">
        <div className="bottom-actions">{children}</div>
      </div>
    </div>
  );
};

export default BottomActions;
