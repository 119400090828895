import React from 'react';
import { Spinner } from 'reactstrap';

export const Loading = ({ size = 'md' }: { size?: string }) => {
  return (
    <div
      data-cy="loading"
      className="h-100 w-100 d-flex justify-content-center align-items-center"
    >
      <Spinner size={size}>Loading...</Spinner>
    </div>
  );
};
